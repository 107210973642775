@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap);

@font-face {
  font-family: jaapokki;
  src: url(/static/media/Jaapokki-Regular.bf89b1e7.otf);
}
:root {

  /* Main Settings  */
  --main-background-color: rgb(29, 25, 32);

  --text-primary-color: rgb(0, 0, 0);
  --text-secondary-color: rgb(226, 226, 226);

  /* Nav Settings  */
  --nav-link-color: #47ebe0;
  --nav-link-hover-color: #8284fc;
  --nav-link-hover-background-color: none;
  --nav-background-color: rgb(131 24 24);
  --nav-height: 80px;
  --nav-logo-width: 100px;
  --nav-logo-font-size: 16px;

  /* Mobile Nav Settings  */

  --mobile-nav-background-color: #000;
  --mobile-nav-logo-height: 100px;
  --mobile-nav-link-color: #47ebe0;
  --mobile-nav-link-hover-color: #8284fc;
  --mobile-nav-link-size: 20px;

  /* Card Settings  */
  --card-background-color: #253DB7;
  --card-border-radius: 10px;
  --card-shadow: 10px 10px 20px -13px rgb(131 24 24);

  /* Minting Area */
  --minting-box-color: #212029;
  --minting-box-heading-color: white;
  --minting-box-info-box-color: rgb(65, 65, 65);
  --minting-box-text-color: white;


  /* Minting Button */
  --minting-button-color: linear-gradient(180deg, #604ae5 0%, #813eee 100%);
  --minting-button-text-color: white;

}

body {
  font-family: jaapokki !important;
  font-style: normal;
  background-color: #000 !important;
  overflow-x: hidden;
  margin: 0;
  padding: 0 !important;
}

.main {
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.preview {
  max-width: 400px;
  border-radius: 20px;
}

.main-content {
  display: flex;
  justify-content: center;
  padding: 30px;
  align-items: center;
  margin-top: 0px;
  flex-direction: row-reverse;
  grid-gap: 50px;
  gap: 50px;

  background: rgba(0, 0, 0, 0.21);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.4px);
  -webkit-backdrop-filter: blur(6.4px);
  border: 1px solid rgba(0, 0, 0, 0.24);
}

.imageleft {
  width: 50%;
}

.imageleft img {
  width: 500px;

  border-radius: 20px;
  margin-top: 30px;
}

.vertical-main-containerr {
  display: grid;
}

.containerr {
  background: #000;
  display: flex;
  flex-flow: column nowrap;
  padding: 30px;
  border-radius: 8px;
  color: #fff;

}

.minted {
  margin-bottom: 5px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.452);
  font-family: jaapokki;
}

.progress {
  text-align: left;
  width: 100%;
}

select {
  display: block;
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23242835%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, transparent 0%, transparent 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 1rem top 0.9rem, 0 0;
  background-size: 0.65em auto, 100%;
  padding: 0.4rem 2rem 0.4rem 1rem;
  scrollbar-width: none;
  -ms-overflow-style: none;
  border: 1px solid #6370e5;
  border-radius: 8px;
  line-height: 1.5;
  color: #6370e5;
  transition: all 200ms ease-in-out;
  font-size: 0.889rem;
}
.minimized{
  z-index: 200!important;
}
.containerr .network-select {
  flex-flow: column;
  margin-bottom: 0.85rem;
}

.network-select label {
  font-size: 0.8rem;
}

div.account-center-actions {
  flex-direction: column;
}

div.account-center-actions>div {
  margin: 8px;
}

.bn-demo-button {
  background: #ffffffee;
  font-size: 0.889rem;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  padding: 0.55rem 1.4rem;
  color: #6370e5;
  margin: 0.4rem 0.75rem;
  transition: background 200ms ease-in-out, color 200ms ease-in-out;
}

.bn-demo-button:focus {
  outline: none;
}

.bn-demo-button:hover {
  cursor: pointer;
}

.selected-toggle-btn {
  background: #6370e5;
  color: white;
}

.unselected-toggle-btn {
  color: #6370e5;
  background: #ffffffee;
}

.ui-settings {
  text-align: center;
}

.ui-settings .conditional-ui-settings {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1375px) {
  .bn-logo-link {
    position: initial;
    margin: auto;
  }
}

@media screen and (max-width: 750px) {
  body {
    overflow: unset;
    overflow-x: hidden;
  }

  main {
    height: unset;
    overflow: unset;
  }

  section {
    flex-direction: column;
  }

  div.containerr {
    width: unset;
  }
}

@media screen and (max-width: 770px) {
  .main-content {
    flex-direction: column;
    align-items: center;
  }

  div.containerr {
    padding: 1rem;
    width: calc(100vw - 4rem);
    text-align: center;
  }
}

@media screen and (max-width: 450px) {
  .containerr .send-transaction-containerr {
    flex-direction: column;
    margin: 4px auto;
  }
}

.minting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 100%;
}

.mintbutton {
  background: #47ebe0;
  color: black;
  border-radius: 10px;
  font-size: 20px;
  padding: 10px 80px;
  font-weight: 800;
  cursor: pointer;
  box-shadow: none;
  border: none;
  transition: all 0.2s;
}
.mintbutton:hover{
  background: #8284fc;
}

.percentage {
  position: absolute;
  top: 18px;
  background: url(/static/media/bubble.468dff4f.svg) no-repeat center;
  height: 28px;
  width: 43px;
  text-align: center;

}

.MuiLinearProgress-bar {
  border-radius: 30px !important;
  background: #0073ff !important;
}

.containerr h2 {
  text-align: left;
  font-family: jaapokki;
  font-weight: 100;
  font-size: 50px;
  margin-top: 0px;
}

.perctext {
  margin-top: 7px !important;
  font-family: jaapokki !important;
  font-weight: 700 !important;
  color: #0073ff !important;

}

.cost h3 {
  font-family: jaapokki;
  color: #fff;
  font-size: 40px;
  font-weight: 100;
  margin-top: 0px;


}

.cost h3 span {
  font-family: jaapokki;
  font-size: 20px;
  font-weight: 100;
}

.cost h2 {
  font-family: jaapokki;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.452);
  margin-bottom: 0px;
  text-align: left;
  font-weight: 400;
}

.quantitymint {
  display: flex;
  justify-content: space-between;
  height: 30px;
  border: 1px solid rgba(255, 255, 255, 0.226);
  padding: 10px;
  align-items: center;
  border-radius: 7px;
}

.quantitymint h2 {
  font-family: jaapokki;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.671);
  font-weight: 400;
  margin-bottom: 0px !important;
}

input {
  background: black;
  border: none;
  font-family: jaapokki;
  font-size: 25px;
  color: white;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.address,
.owner {
  color: white;
}



#logout {
  display: none;
}

.quantitymint {
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
}

.modal-dialog {
  position: relative;
  width: auto;
  pointer-events: none;
  padding-top: 30vh;
}

.modal-backdrop.show {
  opacity: .75;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 98vw;
  height: 100vh;
  background-color: #000;

}

.fade {
  transition: opacity .15s linear;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: block;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-header {
  display: flex;
  overflow-x: hidden;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #adadad;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  overflow-x: hidden;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: .75rem;
  border-top: 1px solid #adadad;
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: rgb(211, 211, 211);
  background-clip: padding-box;
  border: 2px solid rgb(8, 8, 8);
  border-radius: .3rem;
  outline: 0;
  font-family: jaapokki;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-secondary {
  color: #000;
  background-color: #47ebe0;
  border-color: #47ebe0;
  font-family: jaapokki;
  font-weight: 600;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  font-family: jaapokki;
  font-weight: 800;
  font-size: 25px;
}

.modal-header .btn-close {
  padding: .5rem .5rem;
  margin: -.5rem -.5rem -.5rem auto;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: .25em .25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: .25rem;
  opacity: .5;
}

.quantitybuttons {
  display: flex;
  flex-direction: column;
}

.arrowup {
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 0 6px 8px;
  height: 0;
  margin-bottom: 2px;
  width: 0;
  opacity: 0.7;
  cursor: pointer;
}

.arrowdown {
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 8px 6px 0;
  height: 0;
  margin-top: 2px;
  width: 0;
  cursor: pointer;
}

.phases {

  display: flex;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 20px;


}

.flexcenter {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.phase {
  color: white;
  font-family: jaapokki;
  font-size: 18px;
  padding: 9px 9px;
  border-radius: 25px;
  background-color: rgba(85, 85, 85, 0);
  border: 2px solid #000;
  color: #000;
  font-weight: 400;
  width: 100px;
  text-align: center;
  cursor: pointer;
}

.active {
  color: white;
  background: #000;
  border: 2px solid #000;
}

.date {
  border: 1px solid #47ebe0;
  border-radius: 5px;
  color: #47ebe0;
  padding: 3px 10px;
  font-family: jaapokki;
  font-size: 13px;
}

.dates {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0px !important;


}

.datetext {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.452);
  font-family: jaapokki;
}

@media(max-width: 800px) {
  .main-content {
    width: 100%;
  }

  .imageleft {
    width: 80%;
  }

  .imageleft img {
    width: 100%;
  }

  .containerr h2 {
    font-size: 30px;
  }

  .phases {
    width: 100%;
    justify-content: center;
  }

  .cost h3 {
    font-size: 30px;
    text-align: left;
  }

  .quantitymint h2 {
    font-size: 10px;
  }
}


/*REST OF SITE*/

.banner {
  width: 100vw;
  height: 100vh;
  background: url(/static/media/mainback.ba7e263a.jpg) no-repeat center center #000;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
}
.login{
  background: url(/static/media/mainback.ba7e263a.jpg) no-repeat center center #000;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  padding-bottom: 50px;
  padding-top: 50px;
}

nav {
  background: rgb(131 24 24);
  background: var(--nav-background-color);
  width: 80%;
  height: auto;
  transition: height 0.2s ease-in-out;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;

}
.creditbuy{
  margin-top: 20px;
}
.nav-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: auto;
  padding-top: 20px;

}

.nav-logo {
  width: 100px;
  width: var(--nav-logo-width);
}

nav a, .link {
  font-size: 20px;
  color: #47ebe0;
  color: var(--nav-link-color);
  text-decoration: none;
  font-family: jaapokki;
  padding: 15px;
  font-weight: 300;
  width: 100%;
  text-align: center;
  background: transparent;

}

nav a:hover, .link:hover {
  color: #8284fc;

}


.mobile-menu-button {
  height: 30px;
  width: 30px;
  position: fixed;
  top: 30px;
  right: -50px;
  transition: right 0.2s ease-in-out;
  z-index: 200;
  cursor: pointer;

}

.open-menu {
  left: 0px !important;
}

.mobile-nav {
  position: fixed;
  min-height: 100vh;
  background: #000;
  background: var(--mobile-nav-background-color, rgb(117, 69, 69));
  left: -100%;
  z-index: 210;
  width: 100%;
  transition: left 0.3s ease;
}

.mobile-nav-close-button {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 37px;
  right: 37px;
  cursor: pointer;
}

.mobile-nav-close-button img {
  height: 100%;
  width: 100%;
}

.mobile-nav ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 40px;
  list-style: none;
}

.mobile-nav li {
  list-style: none;
  padding: 20px;
}

.mobile-nav a, .mobile-nav .link {
  text-decoration: none;
  color: #47ebe0;
  color: var(--mobile-nav-link-color);
  font-size: 24px;
  font-family: jaapokki;
}

.mobile-nav a:hover {
  color: #8284fc;
  color: var(--mobile-nav-link-hover-color);
}

.mobile-nav-logo {
  height: 50px;
}

.text-muted {
  color: darkgrey;
}

.nav-social {
  width: 20px;

  padding: 0 10px 0 10px;
}

.social-icons {
  display: flex;
  flex-direction: row;
}
.social-icons a{
  width: auto;
}
.social-icons a:hover{
  background: transparent;
}

@media only screen and (max-width: 800px) {
  .hide-800 {
    display: none;
  }

  .mobile-menu-button {
    right: 8vw;
  }


  nav {
    height: 100px;
  }
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-3 {
  padding-bottom: 30px;
}

.pb-4 {
  padding-bottom: 40px;
}

header {
  max-width: 1400px;
  margin: auto;
  align-items: center;
  border-radius: 30px;
  margin-bottom: 40px;
  display: grid;
  grid-gap: 40px;
  gap: 40px;
  padding: 35px;
  flex-direction: column;
}

.mintHeader {
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

@media only screen and (min-width: 800px) {
  header {
    grid-template-columns: 1fr 1fr;
    padding: 40px;
    grid-gap: 20px;
    gap: 20px;
  }
}

.text-primary-color {
  color: rgb(0, 0, 0);
  color: var(--text-primary-color);
}

.text-secondary-color {
  color: rgb(226, 226, 226);
  color: var(--text-secondary-color);
}



.card {
  /* From https://css.glass */
  background: rgba(0, 0, 0, 0.4);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.8px);
  -webkit-backdrop-filter: blur(3.8px);
  border: 1px solid rgba(0, 0, 0, 0.27);
  color: white;
  width: 45%;
}
.vertical-timeline-element-content{
  background: rgba(0, 0, 0, 0.4)!important;
  border-radius: 16px !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(3.8px)!important;
  -webkit-backdrop-filter: blur(3.8px)!important;
  border: 1px solid rgba(80, 80, 80, 0.199)!important;
  color: white;
}

#roadmap h2 {
  color: white;
  font-size: 3rem;
}

#roadmap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.roadmap {
  display: flex;
  grid-gap: 50px;
  gap: 50px;
  max-width: 75rem;
  margin: auto;
}

.card-content {
  padding: 20px;
}

.vertical-timeline-element-content h2 {
  font-size: 1.5rem;
  font-weight: 400;
  color: #47ebe0 !important;
  margin-bottom: 0px;

}

.card-content h5 {
  font-size: 1rem;
  font-weight: 300;
  margin-top: 0px;
}

.vertical-timeline-element-content h3 {
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 150%;
}


.container {
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
}

.content-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1400px;
  margin: auto;
}

.mints-available {
  background: rgb(65, 65, 65);
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.minting-box {
  background-color: #212029 !important;
  background-color: var(--minting-box-color) !important;
  color: white !important;
  color: var(--minting-box-text-color) !important;
}

.minting-box h5 {
  color: white;
  color: var(--minting-box-heading-color);
}

.minting-info {
  background-color: rgb(65, 65, 65);
  background-color: var(--minting-box-info-box-color);
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.minting-info h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.minting-button {
  background: linear-gradient(180deg, #604ae5 0%, #813eee 100%) !important;
  background: var(--minting-button-color) !important;
}

.custom-mint-container {
  margin-bottom: 20px;
}



.loading:after {
  content: " .";
  -webkit-animation: dots 1s steps(5, end) infinite;
          animation: dots 1s steps(5, end) infinite;
}

@-webkit-keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

#introduction {
  padding-top: 100px;
  padding-bottom: 200px;
  position: relative;
}
.introdback{
  position: absolute;
  top: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  mix-blend-mode: screen;
  opacity: 0.2;
}

.introduction {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  color: white;
  max-width: 80%;
  margin: auto;
}
.introduction-content{
  width: 50%;
}
.introduction img{
  width: 30%;
  border-radius: 10px;
}

.introduction p {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1rem;
  font-weight: 300;
}

.introduction h2 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #47ebe0;
}

.navbar {
  background-color: transparent !important;
}

.activenav {

  background-color: #47ebe0 !important;
  transition: background-color linear 1s;
}

.mtop {
  margin-top: 50px;
}


#mint h1 {
  font-family: jaapokki;
  font-size: 80px;
  letter-spacing: 10px;
  color: white;
}

#mint p {
  font-family: jaapokki;
  font-size: 24px;
  letter-spacing: 0.6px;
  color: white;
}

.custom-mint-container img {
  width: 350px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  box-shadow: 0 5px 15px 0px #47ebe0;
  -webkit-transform: translatey(0px);
          transform: translatey(0px);
  -webkit-animation: float 6s ease-in-out infinite;
          animation: float 6s ease-in-out infinite;
}

#mint .MuiTypography-root {
  text-align: center !important;
}
.MuiAccordionDetails-root .MuiTypography-root{
  font-family: Poppins!important;
  font-weight: 300!important;
  font-size: 18px!important;
}
@-webkit-keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }

  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px);
  }

  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}
@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }

  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px);
  }

  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}


.line-sep {
  position: relative;
  width: 100%;
  height: 1px;
  display: block;
  opacity: 0.23;
}

.concept-box {
  position: relative;
  width: 100%;
  display: block;
  text-align: center;
  margin: 0 auto;
}

.concept-box h5 {
  text-align: center;
  font-weight: 600;
}

.concept-box p {
  text-align: center;
}

.concept-box img {
  text-align: center;
  height: 100px;
}

#team h2,
#faq h2 {
  text-align: center;
  color: white;
  font-size: 80px;
  letter-spacing: 5px;
  font-family: jaapokki;
}

footer {
  margin-top: 100px;
  margin-bottom: 50px;
}

footer h1 {
  color: white;
  font-size: 16px;
  text-align: center;
  font-family: jaapokki;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
}

footer .social-icons {
  justify-content: center;
  margin-top: 5px;
}



@media (max-width: 600px) {
  #mint p {
    font-family: jaapokki;
    font-size: 20px;
    max-width: 100%;
    text-align: justify;
  }


  .custom-mint-container img {
    width: 175px;

  }


  .roadmap .phase-card-row .phase-card {
    width: 275px;
    margin-left: auto;
    margin-right: auto;
  }

}

.teamwrap {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

}

.teamcard {
  border-radius: 20px;
  padding: 20px;
  background: white;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.51);
  -webkit-user-select: none !important;
          user-select: none !important;
}

.teamcard:hover {
  -webkit-transform: rotate(2.3deg);
          transform: rotate(2.3deg);
  transition: -webkit-transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
}

.teamcard img {
  width: 350px;
  border-radius: 12px;
}

#mint {
  font-family: jaapokki;
}

.teamcard h3,
.teamcard h4 {
  font-family: jaapokki;
  font-size: 20px;
  margin-left: 10px;
}

.teamcard h3 {
  margin-bottom: 0px;
}

.teamcard h4 {
  margin-top: 0px;
}

#faq {
  width: 80%;
  margin: auto;
  margin-top: 150px;
}

.MuiAccordion-root {
  background: transparent !important;
  box-shadow: none !important;
  border: 3px solid white !important;
  border-radius: 12px !important;
  margin-bottom: 10px;
}

.MuiTypography-root {
  color: white;
  font-family: jaapokki !important;
  font-size: 24px !important;
}

.MuiSvgIcon-root {
  color: white;
  font-size: 40px !important;
}
.vertical-timeline-element-icon{
  width: 30px!important;
  height: 30px!important;
  margin-left: -15px!important;
  top: 15px!important;
}

.club{
  width: 70%;
  margin-inline: auto;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.club h3{
  font-family: jaapokki;
  font-size: 30px;
  color: white;
  margin-bottom: 0px;
}
.club p{
  font-family: jaapokki;
  font-size: 20px;
  color: white;
  margin-top: 30pxpx;
  margin-bottom: 30px;
}
.club a{
  font-family: jaapokki;
  font-size: 20px;
  color: black;
  text-decoration: none;
  background: #47ebe0;
  padding: 10px 40px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}
.club img{
  width: 40%;
  border: 5px solid black;
  outline: 3px solid #47ebe0;
}

.login h2{
  font-family: jaapokki;
  font-size: 30px;
  color: #47ebe0;
  text-align: center;
  margin-bottom: 50px;
}



@media(max-width: 800px) {
  .club{
    flex-direction: column-reverse;
    grid-gap: 30px;
    gap: 30px;
  }
  .login{
    padding-top: 70px;
  }
  .club img{
    width: 100%;
    -webkit-transform: none!important;
            transform: none!important;
  }
  .teamwrap {
    flex-direction: column;
  }

  .banner {

    width: 100vw;
    height: 200px;
    padding-top: 100px;
    background-position: bottom;
  }

  #mint h1 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 5px;
  }


  .content-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }

  div.containerr {
    width: auto;
  }

  .slider-part h1,
  #mint h1,
  #team h2,
  #faq h2 {
    font-size: 60px;
  }

  .teamcard {
    width: 90%;
    margin-bottom: 30px;
  }

  .teamcard img {
    width: 100%;
  }

  #faq h2 {
    margin-top: 0px;
  }

  #faq {
    margin-top: 100px;
  }

  #faq {
    width: 100%;
  }
  .banner{
    height: auto;
    padding-top: 0;
  }
  .main-content{
    flex-direction: column-reverse;
    width: 90%;
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
    margin: auto;
    background: rgba(0, 0, 0, 0.6);
    padding: 0px;
    padding-top: 50px;
    margin-bottom: 50px;
    padding-bottom: 50px;
  }
  .preview{
    width: 300px;
  }
  .main{
    margin-top: 50px;
  }
  .containerr{
    width: 80%!important;
  }
  .roadmap{
    flex-direction: column;
  }
  .card{
    width: 90%;
    margin: auto;
  }
  .nav-container{
    height: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 0px;
    justify-content: flex-start;
    width: 90%;
  }
  .nav-logo{
    height: auto;
    width: 100px;
  }
  .introduction{
    flex-direction: column;
  }
  .introduction-content, .introduction img{
    width: 100%;
  }
  .introduction img{
    padding-top: 50px;
  }
  .vertical-timeline-element-icon{
    
    margin-left: 5px!important;

  }
  #roadmap h2{
    font-size: 2.5rem;
  }
}

* {
  scroll-behavior: smooth;
}
.af-header{
  background: #47ebe0!important;
  color: black!important;
}
.bodyText p span{
  font-family: Poppins!important;
  font-size: 15px!important;
  font-weight: 600!important;
}
.af-form{
  border-radius: 6px;
}
.af-body, .af-footer{
  background:rgb(0, 0, 0)!important;
}
.submit{
  background: #47ebe0!important;
  color: black!important;
  font-size: 15px!important;
  font-weight: 500!important;

}
@media(max-width: 800px){
  .introduction p{
    font-size: 18px!important;
    line-height: 150%;
  }
  #faq{
    width: 90%;
  }
}
